<template>
    <div class="licenceApply">
        <div class="submitForm">
            <el-form ref="form" disabled :model="submitForm" label-position="top" label-width="140px">
                <div class="title">机构信息</div>
                <el-row style="margin-top: 20px">
                    <el-col :span="8">
                        <div>
                            <span>机构名称： </span>
                            <span>{{ submitForm.sysName }}</span>
                        </div>
                    </el-col>
                    <el-col :span="8">
                        <div>
                            <span>机构联系人： </span>
                            <span>{{ submitForm.contactPerson }}</span>
                        </div>
                    </el-col>
                </el-row>
                <el-row style="margin-top: 20px">
                    <el-col :span="8">
                        <div>
                            <span>联系电话： </span>
                            <span>{{ submitForm.contactPhone }}</span>
                        </div>
                    </el-col>
                    <el-col :span="8">
                        <div>
                            <span>所属区域： </span>
                            <span>{{ submitForm.orgDictName }}</span>
                        </div>
                    </el-col>
                </el-row>
                <el-row style="margin-top: 20px">
                    <el-col :span="8">
                        <div>
                            <span>机构地址： </span>
                            <span>{{ submitForm.contactAddress }}</span>
                        </div>
                    </el-col>
                </el-row>
                <el-row class="uploadLine">
                    <div class="title">补贴账户证件</div>
                    <el-row style="margin: 20px 0">
                        <el-col :span="8">
                            <div>
                                <span>养老机构设立许可证号（备案回执编号）： </span>
                                <span>{{ submitForm.licenceNumber }}</span>
                            </div>
                        </el-col>
                        <el-col :span="8">
                            <div>
                                <span>营业执照编号： </span>
                                <span>{{ submitForm.businessLicenseNumber }}</span>
                            </div>
                        </el-col>
                    </el-row>
                    <el-col :span="4" v-if="submitForm.serviceSubsidyApplication" style="text-align: center; margin-top: 20px">
                        <el-form-item prop="image">
                            <img
                                v-if="submitForm.serviceSubsidyApplication"
                                :src="submitForm.serviceSubsidyApplication"
                                @click="$zoomIn([...submitForm.serviceSubsidyApplication])"
                                width="200"
                                height="200"
                                alt=""
                            />
                            <div v-else class="imgEmpty">
                                <i class="el-icon-plus"></i>
                            </div>
                        </el-form-item>
                        <div class="flex-column tip_bottom">
                            <span class="imp">养老机构服务补贴申请书 </span>
                            <span>（加盖公章）</span>
                        </div>
                    </el-col>
                    <el-col
                        v-if="submitForm.applicationType != 2 && submitForm.licenseFiling"
                        :span="4"
                        style="text-align: center; margin-top: 20px"
                    >
                        <el-form-item prop="image">
                            <img
                                v-if="submitForm.licenseFiling"
                                :src="submitForm.licenseFiling"
                                @click="$zoomIn([...submitForm.licenseFiling])"
                                width="200"
                                height="200"
                                alt=""
                            />
                            <div v-else class="imgEmpty">
                                <i class="el-icon-plus"></i>
                            </div>
                        </el-form-item>
                        <div class="flex-column tip_bottom">
                            <span class="imp">养老机构设立许可证/备案 </span>
                            <span>（复印件加盖公章）</span>
                        </div>
                    </el-col>
                    <el-col
                        v-if="submitForm.applicationType != 2 && submitForm.unitRegistration"
                        :span="4"
                        style="text-align: center; margin-top: 20px"
                    >
                        <el-form-item prop="image">
                            <img
                                v-if="submitForm.unitRegistration"
                                :src="submitForm.unitRegistration"
                                @click="$zoomIn([...submitForm.unitRegistration])"
                                width="200"
                                height="200"
                                alt=""
                            />
                            <div v-else class="imgEmpty">
                                <i class="el-icon-plus"></i>
                            </div>
                        </el-form-item>
                        <div class="flex-column tip_bottom">
                            <span class="imp">民办非企业单位登记书</span>
                        </div>
                    </el-col>
                    <el-col v-if="submitForm.businessLicense" :span="4" style="text-align: center; margin-top: 20px">
                        <el-form-item prop="image">
                            <img
                                v-if="submitForm.businessLicense"
                                :src="submitForm.businessLicense"
                                @click="$zoomIn([...submitForm.businessLicense])"
                                width="200"
                                height="200"
                                alt=""
                            />
                            <div v-else class="imgEmpty">
                                <i class="el-icon-plus"></i>
                            </div>
                        </el-form-item>
                        <div class="flex-column tip_bottom">
                            <span>营业执照 </span>
                        </div>
                    </el-col>
                    <el-col v-if="submitForm.foodBusinessLicense" :span="4" style="text-align: center; margin-top: 20px">
                        <el-form-item prop="image">
                            <img
                                v-if="submitForm.foodBusinessLicense"
                                :src="submitForm.foodBusinessLicense"
                                @click="$zoomIn([...submitForm.foodBusinessLicense])"
                                width="200"
                                height="200"
                                alt=""
                            />
                            <div v-else class="imgEmpty">
                                <i class="el-icon-plus"></i>
                            </div>
                        </el-form-item>
                        <div class="flex-column tip_bottom">
                            <span>食品经营许可证 </span>
                            <span>（复印件加盖公章）</span>
                        </div>
                    </el-col>
                </el-row>
                <div v-if="submitForm.otherCertificates.length">
                    <div class="title" style="margin: 20px 0">其他证件</div>
                    <div style="display: flex">
                        <div style="margin-right: 20px" v-for="(item, index) in submitForm.otherCertificates" :key="item">
                            <el-form-item :prop="'image' + index">
                                <img @click="$zoomIn(submitForm.otherCertificates)" :src="item" width="200" height="200" alt="" />
                            </el-form-item>
                        </div>
                    </div>
                </div>
                <div class="title">补贴账户信息</div>
                <el-row style="margin: 20px 0">
                    <el-col :span="8">
                        <div>
                            <span>收款账号： </span>
                            <span>{{ submitForm.collectionNumber }}</span>
                        </div>
                        <div style="margin-top: 20px">
                            <span>收款账户（ 必须为收款账号对应的收款账户名称 ）： </span>
                            <span>{{ submitForm.collectionAccount }}</span>
                        </div>
                        <!--   <el-form-item label="收款账号">
              <el-input v-model="submitForm.collectionNumber" class="wf300" />
            </el-form-item>
            <el-form-item label="收款账户（ 必须为收款账号对应的收款账户名称 ）">
              <el-input v-model="submitForm.collectionAccount" class="wf300 tip" />
            </el-form-item>-->
                    </el-col>
                    <el-col :span="8">
                        <div>
                            <span>开户行： </span>
                            <span>{{ submitForm.bankOfDeposit }}</span>
                        </div>
                        <!--     <el-form-item label="开户行">
              <el-input v-model="submitForm.bankOfDeposit" class="wf300" />
            </el-form-item>-->
                    </el-col>
                </el-row>
                <div class="title">床位信息</div>
                <el-row style="margin: 20px 0">
                    <el-col :span="4">
                        <div>
                            <span>总床位数（张）： </span>
                            <span>{{ submitForm.totalBeds }}</span>
                        </div>
                        <!--    <el-form-item label="总床位数（张）">
              <el-input-number v-model="submitForm.totalBeds" :min="0" label="描述文字" />
            </el-form-item>-->
                    </el-col>
                    <el-col :span="8">
                        <div>
                            <span>护理床位数（张）： </span>
                            <span>{{ submitForm.nursingBeds }}</span>
                        </div>
                        <!--    <el-form-item label="护理床位数（张）">
              <el-input-number v-model="submitForm.nursingBeds" :min="0" label="描述文字" />
            </el-form-item>-->
                    </el-col>
                </el-row>
                <div class="title">审核记录</div>
                <el-row style="margin-top: 10px">
                    <el-col :span="12">
                        <el-table :data="submitForm.auditRecordVoList" border style="width: 100%" :header-cell-style="headerCellStyle">
                            <template slot="empty">
                                <IsEmpty />
                            </template>
                            <el-table-column type="index" label="序号" align="center" width="80" />
                            <el-table-column prop="type" align="center" label="操作类型">
                                <template v-slot="scope">
                                    <span>{{ ['提交', '审核'][scope.row.type] }}</span>
                                </template>
                            </el-table-column>
                            <el-table-column prop="state" align="center" label="审核状态">
                                <template v-slot="scope">
                                    <span>{{ ['通过', '不通过'][scope.row.state] }}</span>
                                </template>
                            </el-table-column>
                            <el-table-column prop="creatTime" align="center" label="操作时间" />
                            <el-table-column prop="governmentName" align="center" label="操作单位" />
                            <el-table-column prop="examineUserName" align="center" label="操作人">
                                <template v-slot="scope">
                                    <span>{{ scope.row.examineUserName || scope.row.governmentName }}</span>
                                </template>
                            </el-table-column>
                            <el-table-column prop="refusalCause" align="center" label="不通过原因" />
                        </el-table>
                    </el-col>
                </el-row>
            </el-form>
            <div class="btn">
                <el-button style="width: 120px; margin-right: 30px" @click="$router.go(-1)">返回</el-button>
            </div>
        </div>
    </div>
</template>

<script>
import { details } from '@/api/institutionalSubsidies'
export default {
    name: 'LicenceApply',
    data() {
        return {
            submitForm: {
                licenseFiling: '',
                orgDictName: '',
                serviceName: '',
                contactPerson: '',
                contactPhone: '',
                contactAddress: '',
                licenceNumber: '',
                businessLicenseNumber: '',
                serviceSubsidyApplication: '',
                unitRegistration: '',
                businessLicense: '',
                foodBusinessLicense: '',
                otherCertificates: [],
                collectionNumber: '',
                collectionAccount: '',
                bankOfDeposit: '',
                totalBeds: '',
                nursingBeds: '',
                auditRecordVoList: []
            },
            rowId: ''
        }
    },
    beforeRouteEnter(to, from, next) {
        to.meta.title = '查看'
        next()
    },
    mounted() {
        this.rowId = this.$route.query.rowId
        this.detailsData()
    },
    methods: {
        async detailsData() {
            const res = await details({ id: this.rowId })
            this.submitForm = res.data
            this.submitForm.otherCertificates = res.data.otherCertificates ? res.data.otherCertificates.split(',') : []
        }
    }
}
</script>

<style lang="scss" scoped>
/deep/ .el-form--label-top .el-form-item__label {
    padding: 0;
}
/deep/ .el-form-item {
    margin-bottom: 10px;
}
.btn {
    margin-top: 30px;
    text-align: center;
}
.licenceApply {
    width: 100%;
    padding: 15px 20px;
    background: #fff;
    .submitForm {
        margin-top: 10px;
        .title {
            font-size: 18px;
            font-weight: bold;
            margin-top: 20px;
        }
        .tip {
            color: #7f7f7f;
            margin-bottom: 10px;
        }
        .tip_bottom {
            line-height: 20px;
        }
        .uploadLine {
            margin-top: 20px;
        }
        .imp::before {
            content: '*';
            color: #ff4949;
            margin-right: 4px;
        }
    }
    .wf300 {
        width: 300px;
    }
}
.imgEmpty {
    width: 200px;
    height: 200px;
    border: 1px dashed #c0c4cc;
    display: inline-block;
    text-align: center;
    background-color: #fbfdff;
    margin-top: 10px;
    i {
        font-size: 32px;
        color: #c0c4cc;
        text-align: center;
        line-height: 200px;
    }
}
img {
    cursor: pointer;
}
/*  .tip {
    position: relative;
  }
  .tip:after {
    content: '必须为收款账号对应的收款账户名称';
    position: absolute;
    right: -250px;
    top: 0px;
  }*/
span {
    font-size: 14px !important;
}
</style>
